export const errorsForm = {}

export const validateEmail = word => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
  return re.test(String(word).toLowerCase())
}

export const validateNames = word => {
  const re = /^[a-zA-Z\u00C0-\u017F\s]{2,51}$/
  if (word.length > 50) {
    return false
  } else if (re.test(word)) {
    return true
  } else if (re.test(word)) {
    return true
  } else if (word.length < 50) {
  } else {
    return true
  }
  /*  const re = /^[a-zA-Z\u00C0-\u017F\s]{2,30}$/
  return re.test(String(word).toLowerCase()) */
}

export const validateApellidos = word => {
  const re = /^[a-zA-Z\ áéíóúÁÉÍÓÚñÑ\s]*$/
  if (!re.test(word)) {
    return false
  } else if (word === "") {
    return false
  } else {
    return true
  }
}

export const validateNickname = word => {
  if (word.length > 24) {
    return false
  } else if (word === "") return false
  else {
    return true
  }
}

export const validatePhone = word => {
  if (word.length > 1) {
    return true
  } else if (word === "") {
    return false
  } else if (!/^[0-9]/.test(word)) return false
  if (word.length > 10) {
    return false
  } else {
    return true
  }
}

export const validateWeight = word => {
  if (word.length > 6) {
    return false
  } else if (!/^([0-9]{1,3})+(\.[0-9]{1,2})?$/.test(word)) {
    return false
  } else if (parseInt(word) < 0 || parseInt(word) > 999) {
    return false
  } else if (!word) {
    return false
  } else {
    return true
  }

  //^([0-999]{1,3}(\.[0-9]{1,2})?)$
}

export const validateHeight = word => {
  if (word.length > 4) {
    return false
  } else if (!/^([0-9]{1,2})+(\.[0-9]{1,2})?$/.test(word)) {
    return false
  } else if (parseInt(word) < 0 || parseInt(word) > 9) {
    return false
  } else if (!word) {
    return false
  } else {
    return true
  }
}

export const validateAge = word => {
  if (!/\d/.test(word)) {
    return false
  } else if (word.length > 3) {
    return false
  } else if (parseInt(word) < 0 || parseInt(word) > 99) {
    return false
  } else if (word === "" || word === undefined) return false
  else {
    return true
  }
}

export const validateNumbers = digit => {
  const re = /^[0-9]{15,19}$/
  return re.test(String(digit))
}

export const validateNumber = digit => {
  const re = /^[0-9]{1,32}$/
  return re.test(String(digit))
}

export const justLetters = word => {
  const re = /^[a-zA-Z\u00C0-\u017F\s]/
  return re.test(String(word).toLowerCase())
}
