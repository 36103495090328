import React, { useState, useEffect, Children } from "react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import MailIcon from "@material-ui/icons/Mail"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"

function InputTexfieldRegex({
  onChange,
  setOnChange,
  property,
  validateFunc,
  placeHolder,
  type,
  children,
}) {
  const [checkIconValidated, setCheckIconValidated] = useState(null)

  let regexCase = undefined

  switch (type) {
    case "string":
      regexCase = /^[a-zA-Z\u00C0-\u017F\s]/
      break
    case "int":
      regexCase = /^[0-9]/
      break
    case "float":
      regexCase = /([0-9]{1,3})(\.[0-9]{0,2})?|(\.[0-9]+)/
      break
    default:
      type = "other"
      break
  }

  const handleChange = event => {
    // console.log(event.target.value == '')
    //console.log(onChange)

    if (type == "float" && event.target.value == "") {
      setOnChange(property, "")
    }
    if (type == "int" && event.target.value == "") {
      setOnChange(property, "")
    }
    //

    if (type === "float") {
      //Opción para flotantes
      if (
        (regexCase && regexCase.test(String(event.target.value))) ||
        type == "other"
      ) {
        if (
          property === "weight" &&
          regexCase.test(String(event.target.value))
        ) {
          if (
            event.target.value.length < 7 &&
            (/[0-9]/.test(event.target.value[event.target.value.length - 1]) ||
              /\./.test(event.target.value[event.target.value.length - 1]))
          ) {
            if (setOnChange) {
              console.log(regexCase)
              setOnChange(property, event.target.value)
            }
          } else {
            console.log(event.target.value)
            setCheckIconValidated("incorrect")
          }
        } else if (property === "height") {
          if (
            event.target.value.length < 5 &&
            (/[0-9]/.test(event.target.value[event.target.value.length - 1]) ||
              /\./.test(event.target.value[event.target.value.length - 1]))
          ) {
            if (setOnChange) {
              setOnChange(property, event.target.value)
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else {
          if (setOnChange) {
            setOnChange(event.target.value)
          }
        }
      } else {
        setCheckIconValidated("incorrect")
      }
    } else {
      //Opción para las demás secciones
      if (
        (regexCase &&
          regexCase.test(
            String(event.target.value[event.target.value.length - 1])
          )) ||
        type == "other"
      ) {
        if (property === "firstName") {
          if (event.target.value.length <= 25) {
            if (setOnChange) {
              setOnChange(property, event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else if (property === "lastName") {
          if (event.target.value.length <= 25) {
            if (setOnChange) {
              setOnChange(property, event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else if (property === "userName") {
          if (event.target.value.length <= 50) {
            if (setOnChange) {
              setOnChange(property, event.target.value)
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else if (property === "tel") {
          if (event.target.value.length <= 10) {
            if (setOnChange) {
              setOnChange(property, event.target.value)
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else if (property === "age") {
          if (event.target.value.length <= 2) {
            if (setOnChange) {
              setOnChange(property, event.target.value)
            }
          } else {
            setCheckIconValidated("incorrect")
          }
        } else {
          if (setOnChange) {
            setOnChange(property, event.target.value)
          }
        }
      } else {
        setCheckIconValidated("incorrect")
      }
    }
  }

  useEffect(() => {
    // console.log(validateFunc(onChange))
    if (validateFunc(onChange)) {
      setCheckIconValidated("pass")
    } else {
      if (onChange == "" || onChange == undefined) {
        setCheckIconValidated(null)
      } else {
        setCheckIconValidated("incorrect")
      }
    }
  }, [onChange])

  return (
    <>
      <TextField
        className="w-full"
        //    id={`input-with-icon-textfieldo${onChange}`}
        placeholder={placeHolder}
        value={onChange}
        size="small"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{children}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {checkIconValidated === null && (
                <CheckIcon style={{ color: "#6B6B6B" }}></CheckIcon>
              )}
              {checkIconValidated === undefined && (
                <CheckIcon style={{ color: "#6B6B6B" }}></CheckIcon>
              )}
              {checkIconValidated === "pass" && (
                <CheckIcon style={{ color: "#06800a" }}></CheckIcon>
              )}
              {checkIconValidated === "incorrect" && (
                <CloseIcon style={{ color: "#FF0000" }}></CloseIcon>
              )}
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default InputTexfieldRegex
